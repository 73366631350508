import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/layout";

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }
`;

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(false);
  const [showContact, setShowContact] = useState(false);

  function handleShowNav() {
    setShowNav(true);
  }
  function handleHideNav() {
    setShowNav(false);
  }
  function handleShowContact() {
    setShowContact(true);
  }
  function handleHideContact() {
    setShowContact(false);
  }
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          );
        }
        return (
          <Layout
            {...props}
            siteTitle={data.site.title}
            showNav={showNav}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
            showContact={showContact}
            onHideContact={handleHideContact}
            onShowContact={handleShowContact}
          />
        );
      }}
    />
  );
}

export default LayoutContainer;
