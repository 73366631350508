import React from "react";
import { cn } from "../lib/helpers";
import { responsiveTitle1, pageTitle } from "./typography.module.css";
import styles from "./careers-page.module.css";
import PageSections from "./page-sections";

const CareersPage = (props) => {
  const { pageTitle: title, _rawContent, content } = props;

  const sectionContent = _rawContent ? _rawContent : content;

  return (
    <main className={cn(styles.root)}>
      {title && <h2 className={cn(responsiveTitle1, pageTitle, styles.title)}>{title}</h2>}
      <PageSections content={sectionContent} />
    </main>
  );
};

export default CareersPage;
