import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import QCareersPage from "../queries/QCareersPage";
import CareersPage from "../components/careers-page";

const Careers = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <QCareersPage>
      {({ careersPage }) => (
        <Layout>
          <SEO title={careersPage.pageTitle} />
          <CareersPage {...careersPage} />
        </Layout>
      )}
    </QCareersPage>
  );
};

export default Careers;
