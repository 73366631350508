import React from "react";
import { StaticQuery, graphql } from "gatsby";

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={({ careersPage }) => {
      return children({
        careersPage,
      });
    }}
  />
);
const query = graphql`
  query CareersPage {
    careersPage: sanityCareersPage {
      pageTitle
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;
